import { createTheme } from '@mui/material/styles';

// import { createTheme } from '@material-ui/core/styles';

// Main color palette
const palette = {
  primary: {
    main: '#FFF494', ///pale yellow as primary color
    // main: '#BFFFF2', // pale pastel aquamarine as primary color
  },
  secondary: {
    main: '#7875FF', 
    // main: '#E1BEE7', // pale pastel violet as secondary color
  },
  error: { main: '#f44336' },
  warning: { main: '#ff9800' },
  info: { main: '#2196f3' },
  success: { main: '#4caf50' },
  text: {
    primary: '#000000', // black for primary text
    secondary: '#4A4141', // a shade of dark gray for secondary text
  },
  background: { default: '#F8FFFE'},
  // background: { default: '#F3EAFF'},
};

// Feature-specific accent colors
const featureColors = {
  sleepself: '#5f797b',
  activeself: '#e07a5f',
  balanceself: '#81b29a',
  moodself: '#babf95',
  nutriself: '#f2cc8f',
};

// Typography settings
const typography = {
  fontFamily: [
    'DM Mono',
    'Poppins',
    'Open Sans',
    'Roboto Condensed',
    'Arial',
    'sans-serif',
  ].join(','),
  h1: {
    fontFamily: 'DM Mono',
    fontWeight: 600,
    fontSize: '2.5rem',
  },
  h2: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '2.2rem',
  },
  h3: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '2rem',
  },
  home3: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '5rem',
    color: '#000'
  },
  h4: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '1.8rem',
    color: '#000'
  },
  h5: {
    fontFamily: 'DM Mono',
    fontWeight: 400,
    fontSize: '1.6rem',
  },
  h6: {
    fontFamily: 'Open Sans',
    fontWeight: 300,
    fontSize: '1.4rem',
  },
  subtitle1: {
    fontFamily: 'Open Sans',
    fontSize: '1.2rem',
  },
  subtitle2: {
    fontFamily: 'Open Sans',
    fontSize: '1rem',
  },
  body1: {
    fontFamily: 'Open Sans',
    fontSize: '1rem',
  },
  body2: {
    fontFamily: 'Open Sans',
    fontSize: '0.875rem',
  },
  button: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 700,
    borderRadius: '0px',
    fontSize: '1rem',
    color: '#000',
  },
  caption: {
    fontFamily: 'Roboto Condensed',
    fontSize: '0.75rem',
  },
  overline: {
    fontFamily: 'Roboto Condensed',
    fontSize: '0.625rem',
  },
};

// Create theme instance
const theme = createTheme({
  palette,
  typography,
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            borderRadius: 4, // Slightly rounded corners
            padding: '10px', // Adequate padding
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#c4c4c4', // Subtle border color
            },
            '&.Mui-focused .MuiInputLabel-root': {
              color: '#000', // Replace with the color you want for the label on focus
            },
            '&:hover fieldset': {
              borderColor: '#a0a0a0', // Slightly darker on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: '#000', // Color when focused
            },
            '&.Mui-focused .MuiInputBase-input': {
              color: '#000', // Text color when focused (replace '#desired-color' with your color)
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiInputLabel-root': {
              color: '#000', // Color for the label when focused
            },
          },
          '& .MuiInputLabel-root': {
            // Label styles
            color: '#000',
          },
          '& .MuiInputBase-input': {
            // Input text styles
            color: '#000',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            borderRadius: 4, // Slightly rounded corners
            padding: '10px', // Adequate padding
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#c4c4c4', // Subtle border color
            },
            '&.Mui-focused .MuiInputLabel-root': {
              color: '#000', // Replace with the color you want for the label on focus
            },
            '&:hover fieldset': {
              borderColor: '#a0a0a0', // Slightly darker on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: '#000', // Color when focused
            },
            '&.Mui-focused .MuiInputBase-input': {
              color: '#000', // Text color when focused (replace '#desired-color' with your color)
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiInputLabel-root': {
              color: '#000', // Color for the label when focused
            },
          },
          '& .MuiInputLabel-root': {
            // Label styles
            color: '#000',
          },
          '& .MuiInputBase-input': {
            // Input text styles
            color: '#000',
          },
        },
      },
    },
  },
});

export default theme;
export { featureColors };