// src/pages/LandingPage.js
import React from 'react';
import { Box, Button, Typography, Container, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import HeroImage from '../images/dash-transparent.png';
import Background from '../images/bg.png';


const HeroContainer = ({ children }) => (
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Adjust height as needed
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 4
  }}>
    <Container maxWidth="lg">
      {children}
    </Container>
  </Box>
);

const LandingPage = () => {

  return (
      <HeroContainer>
      <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' }, // Stack on small screens, row on medium and up
          alignItems: 'center', // Center items vertically
          justifyContent: 'space-between', 
          width: '100%' 
        }}>
          {/* Left Side: App Name and Strapline */}
          <Box sx={{ maxWidth: { xs: '100%', md: '50%' }, mb: { xs: 2, md: 0 } }}>
            <Typography variant="home3" gutterBottom>
              Grow Yourself
            </Typography>
            <Typography variant="h5" color="textSecondary" paragraph>
              Coming Soon
            </Typography>
            <Typography variant="h6" color="textSecondary" paragraph>
              A groundbreaking AI-powered app that will identify and predict your stress triggers, recommend practical, personalised management strategies, and provide tools to help you build resilience.
            </Typography>
          </Box>

          {/* Right Side: Image (Optional) */}
          <Box 
            component="img" 
            src={HeroImage} 
            alt="Hero Image" 
            sx={{ 
              maxWidth: '100%', 
              height: 'auto', 
              objectFit: 'contain', // Maintain aspect ratio
              maxWidth: { xs: '100%', md: '50%' } // Full width on small screens, half on medium and up
            }} 
          />
        </Box>
      </HeroContainer>
  );
};

export default LandingPage;



