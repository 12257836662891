import React, {useState} from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
    const location = useLocation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const isActive = (path) => location.pathname === path;
    const activeStyle = { backgroundColor: '#FFF9C4', color: 'black', borderRadius: '0px' };

    const renderNavItems = () => (
        <>
            <Button color="inherit" component={Link} to="/terms-of-service">ToS</Button>
            <Button color="inherit" component={Link} to="/privacy-policy">Privacy Policy</Button>
            <Button color="inherit" component={Link} to="/about">About</Button>
        </>
    );

    return (
        <div style={{ flexGrow: 1 }}>
            <AppBar position="static" style={{ backgroundColor: 'theme.palette.color.primary' }}> {/* pale pastel aquamarine */}
                <Toolbar>
                    <Typography
                        variant="h4"
                        sx={{ flexGrow: 1, cursor: 'pointer' }}
                        component={Link}
                        to="/"
                        style={{ color: 'black', textDecoration: 'none' }} // black text
                    >
                        Gy
                    </Typography>

                    {isMobile && (
                        <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            onClick={() => setDrawerOpen(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}

                    {!isMobile && renderNavItems()}

                    {isMobile && (
                        <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)} sx={{ '& .MuiDrawer-paper': { width: '250px' } }}>
                            {renderNavItems()}
                        </Drawer>
                    )}
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Navbar;